import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { getPrograms, getDefaultSEO, getFeaturedImageDataFromWpPost } from '../../utils';
import Seo from '../../components/Seo';
import { HT_DEFAULT_PAGE_NAME, HT_PAGE_CATEGORY_ARCHIVE_PAGE, MAX_NUMBER_OF_POSTS_PER_PAGE } from '../../constants';
import AirlineMilesPrograms from '../../components/EarnAndUsePoints/AirlineMilesPrograms';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IAirlineMilesProgramsTemplateData {
  posts: Queries.WpPostConnection;
}

const AirlineMilesProgramsTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IAirlineMilesProgramsTemplateData>) => {
  const {
    posts: { nodes },
  } = data;
  const currentPageIndex = (pageContext as any)?.pageNumber - 1 || 0;
  let posts: Queries.WpPost[] = [];

  if (nodes && Array.isArray(nodes)) {
    posts = nodes;
  }
  const title = 'Airline Miles Programs';
  const programs = getPrograms(posts);

  const totalNumberOfPages = (pageContext as any)?.totalNumberOfPages || 0;
  return (
    <HTPageWrapper
      category={"Earn & Use Points, Airlines, " + HT_PAGE_CATEGORY_ARCHIVE_PAGE}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <AirlineMilesPrograms
        pageHeader={{
          title: title,
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
            {
              link: '/earn-use-points/',
              text: 'Earn & Use Points',
            },
            {
              link: '/earn-use-points/airlines/',
              text: 'Airline Miles Programs',
            },
          ],
          subTitle:
            'Learn how to always travel in style, with our detailed expert guides on earning and using airline miles.',
        }}
        programs={{
          programs,
        }}
        pagination={{
          currentPageIndex,
          pageSize: MAX_NUMBER_OF_POSTS_PER_PAGE,
          numberOfPages: totalNumberOfPages,
          basePath: '/earn-use-points/airlines',
        }}
      />
    </HTPageWrapper>
  );
};

export default AirlineMilesProgramsTemplate;

export const Head = ({ location, data }: PageProps<IAirlineMilesProgramsTemplateData>) => {
  const pageTitle = 'Airline Points Programs | UpgradedPoints.com';
  const socialTitle = pageTitle;
  const ogDescription =
    'Learn how to make the most of airline points. Our experts show you how to earn the most points on your purchases and how to use them to travel in luxury.';
  const includeCollectionPageType = true;
  let pathname = location.pathname;
  if (!pathname.endsWith('/')) pathname += '/';
  const pageNumber = pathname.includes('page') ? parseInt(pathname.slice(-2, -1)) : 1;
  const extraBreadcrumbList = [
    { name: 'Earn & Use Points', slug: 'earn-use-points' },
    { name: 'Airline Miles Programs', slug: 'airlines' },
  ];
  if (pageNumber > 1) {
    extraBreadcrumbList.push({
      name: 'Airline Miles Programs - Page ' + pageNumber,
      slug: 'page/' + pageNumber,
    });
  }
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  const firstPost = data?.posts?.nodes[0];
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return (
    <Seo
      title={pageTitle}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      pageNumber={pageNumber}
    />
  );
};

export const query = graphql`
  query AirlineMilesPrograms($start: Int, $tagName: String) {
    posts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { name: { regex: $tagName } } } } }
      limit: 21
      skip: $start
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
      }
    }
  }
`;
