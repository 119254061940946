import React, { FC } from 'react';
import { IPrograms } from './Programs.def';
import Scroller from '../Scroller/Scroller';
import { renderItems } from '../../utils';

const Programs: FC<IPrograms> = ({ programs, isScrollable, title, moreLink, subTitle }) => {
  const scrollerSettings = {
    defaultTilesToShow: 3,
    responsive: [
      {
        breakpoint: 1023,
        tilesToShow: 2,
      },
    ],
  };
  return (
    <div className="programs">
      <div className="blockHeader">
        <div className="titles">
          {title && <h2>{title}</h2>}
          {subTitle && <p>{subTitle}</p>}
        </div>
        {moreLink && (
          <a href={moreLink.link} className={`btn-standard ${subTitle && 'hasSubtitle'}`}>
            {moreLink.text ? moreLink.text : ''}
          </a>
        )}
      </div>
      {isScrollable ? (
        <Scroller className="programs-scroller" scrollerSettings={scrollerSettings}>
          {renderItems(programs, 'Program')}
        </Scroller>
      ) : (
        <div className="programs__container">{renderItems(programs, 'Program')}</div>
      )}
    </div>
  );
};

export default Programs;
